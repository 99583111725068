.grab{
  cursor: grab;
}

.tns-nav{
  text-align: center !important;
}

.bg-light-success{
  background-color: '#a7e453' !important
}
